import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  Card,
  CardContent,
  Typography,
  Grid,
  Link,
  CircularProgress,
  Box
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { getPlayerWithFullHistory } from '../../api/apiService';
import FloatingSelectionFilter from '../Filter/FloatingSelectionFilter';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import colors from '../../config/colors';
import HeadWrapper from '../HeadWrapper/HeadWrapper';

import logo from '../../assets/Rankings.png';


const PlayerPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [timeScale, setTimeScale] = useState('All');
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const extraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const payload = { id };
    getPlayerWithFullHistory(payload)
      .then((response) => {
        setPlayer(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [id]);

  const handleTimeScaleChange = (value) => {
    setTimeScale(value);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!player) {
    return <Alert severity="error" sx={{ marginTop: 2, marginBottom: 2, fontFamily: 'Avenir' }}>Error loading player data. Please come back later.</Alert>
  }

  const { name, value, rank, fullValueHistory, playerInfo } = player;
  const { age, positions, team, level } = playerInfo;

  // Updated: Transforming fullValueHistory (list) into the format for recharts
  const data = fullValueHistory.map((entry) => ({
    date: entry.eventDate,
    value: entry.value,
    rank: entry.rank,
  }));

  const now = new Date();
  let filteredData = data;

  if (timeScale !== 'All') {
    const filterDate = (days) => {
      const pastDate = new Date(now);
      pastDate.setDate(now.getDate() - days);
      return pastDate;
    };

    const scales = {
      '1 Year': filterDate(365),
      '1Y': filterDate(365),
      '6 Months': filterDate(183),
      '6M': filterDate(183),
      '1 Month': filterDate(30),
      '1M': filterDate(30),
      '1 Week': filterDate(7),
      '1W': filterDate(7),
    };

    const filterByDate = scales[timeScale];
    filteredData = data.filter((entry) => new Date(entry.date) >= filterByDate);
  }

  const renderChangeIcon = (value) => {
    if (value === 0) {
      return null;
    }
    const iconProps = {
      sx: { fontSize: smallScreen ? '35px' : '50px' }, 
    };
    return value < 0 ? <TrendingDownIcon color="error" {...iconProps} /> : <TrendingUpIcon color="success" {...iconProps} />;
  };

  const minValue = Math.min(...filteredData.map((entry) => entry.value));
  const maxValue = Math.max(...filteredData.map((entry) => entry.value));
  const minY = Math.max(0, Math.floor((minValue - 100) / 100) * 100);
  const maxY = Math.min(10000, Math.ceil((maxValue + 100) / 100) * 100);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#333',
            border: '1px solid #444',
            padding: smallScreen ? '5px' : '10px',
            borderRadius: '10px',
            fontSize: smallScreen ? '10px' : '14px',
            color: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
          }}
        >
          <p
            style={{
              color: colors.secondary400,
              margin: '0 0 5px 0',
              fontWeight: 'bold',
            }}
          >{`${label}`}</p>
          <p style={{ margin: '0' }}>{`Value: ${payload[0].value}`}</p>
          <p style={{ margin: '0' }}>{`Rank: ${payload[0].payload.rank}`}</p>
        </div>
      );
    }

    return null;
  };

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}-${day}`;
  };

  const renderYearLabels = () => {
    const uniqueYears = Array.from(
      new Set(filteredData.map((entry) => new Date(entry.date).getFullYear()))
    );
    return uniqueYears.map((year) => (
      <text
        key={year}
        x={`${
          (filteredData.findIndex(
            (entry) => new Date(entry.date).getFullYear() === year
          ) /
            filteredData.length) *
          100
        }%`}
        y={270}
        textAnchor="middle"
        fontSize={12}
        fill="rgba(0, 0, 0, 0.5)"
      >
        {year}
      </text>
    ));
  };

  const getRecentChange = (days) => {
    const recentDate = new Date();
    recentDate.setDate(recentDate.getDate() - days);
    const recentEntries = data.filter(entry => new Date(entry.date) >= recentDate);
    const initialEntry = recentEntries[0] || { value: value, rank: rank };
    const latestEntry = recentEntries[recentEntries.length - 1] || initialEntry;
    const valueChange = latestEntry.value - initialEntry.value;
    const rankChange = initialEntry.rank - latestEntry.rank;
    const valueChangePercentage = ((valueChange / initialEntry.value) * 100).toFixed(2);
    
    return {
      valueChange,
      valueChangePercentage,
      rankChange,
    };
  };

  const valueChange30Days = getRecentChange(30);
  const valueChange7Days = getRecentChange(7);
  const rankChange30Days = getRecentChange(30);
  const rankChange7Days = getRecentChange(7);

  const earliestDate = data.length > 0 ? new Date(Math.min(...data.map((entry) => new Date(entry.date)))) : null;
  const formattedEarliestDate = earliestDate ? earliestDate.toISOString().split('T')[0] : null;

  const displayValue = (changeValue) => changeValue === 0 ? '–' : changeValue;

  return (
    <>
    <HeadWrapper 
      title={`${name} Player Page`} 
      description={`Dynasty baseball trends and insights for ${name}. View player value history since ${formattedEarliestDate}.`} 
      image={logo} 
    />
    <Card style={{ marginTop: '10px' }}>
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{                
            borderBottom: '1px solid #ddd',
            paddingBottom: '10px',
            marginBottom: '10px'
          }}
        >
          <Grid item>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontFamily: 'Avenir',
                fontWeight: 'bold'
              }}
            >
              {name}
            </Typography>
          </Grid>
          {extraSmallScreen && <Grid item>
            <Link
              component="button"
              onClick={() => navigate(-1)}
              variant="subtitle2"
              sx={{ marginLeft: 'auto', textDecoration: 'none', paddingBottom: '0px', marginBottom: '-3px' }}
            >
              Back
            </Link>
          </Grid>}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Typography color="textSecondary" fontFamily="Avenir" fontWeight="bold">
              Age: <span style={{ fontWeight: 'normal' }}>{age ? age : 'NA'}</span>
            </Typography>
            <Typography color="textSecondary" fontFamily="Avenir" fontWeight="bold">
              Position: <span style={{ fontWeight: 'normal' }}>{positions ? positions.join(", ") : 'NA'}</span>
            </Typography>
            <Typography color="textSecondary" fontFamily="Avenir" fontWeight="bold">
              Team: <span style={{ fontWeight: 'normal' }}>{team ? team : 'NA'}</span>
            </Typography>
            <Typography color="textSecondary" fontFamily="Avenir" fontWeight="bold">
              Level: <span style={{ fontWeight: 'normal' }}>{level ? level : 'NA'}</span>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography color="textSecondary" fontFamily="Avenir" fontWeight="bold">
              Value: <span style={{ fontWeight: 'normal' }}>{value}</span>
            </Typography>
            <Typography color="textSecondary" fontFamily="Avenir" fontWeight="bold">
              Rank: <span style={{ fontWeight: 'normal' }}>{rank}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ fontFamily: 'Avenir', fontWeight: 'bold', marginTop: '20px', paddingLeft: '6px' }}
              sx={{ borderBottom: '1px solid #ddd', paddingBottom: '6px', marginBottom: '10px' }}
            >
              Quick Insights
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={3}>
                <Box sx={{ bgcolor: colors.secondary100, border: 1, borderColor: colors.secondary500, borderRadius: 3, padding: 2, paddingTop: 1 }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" sx={{ fontFamily: 'Avenir', fontWeight: 'bold', fontSize: extraSmallScreen ? '.7rem' : '1rem' }} gutterBottom>
                      Value Change
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontFamily: 'Avenir', fontSize: extraSmallScreen ? '.7rem' : '1rem' }} gutterBottom>
                      {extraSmallScreen ? "7d" : "7 day"}
                    </Typography>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: extraSmallScreen ? '35px' : '50px'
                  }}>
                    <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: extraSmallScreen ? '1.7rem' : '2rem', fontFamily: 'Avenir' }}>
                      <span style={{ paddingRight: '8px' }}>{displayValue(valueChange7Days.valueChange)}</span> {renderChangeIcon(valueChange7Days.valueChange)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={3}>
                <Box sx={{ bgcolor: colors.secondary100, border: 1, borderColor: colors.secondary500, borderRadius: 3, padding: 2, paddingTop: 1 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle1" sx={{ fontFamily: 'Avenir', fontWeight: 'bold', fontSize: extraSmallScreen ? '.7rem' : '1rem' }} gutterBottom>
                    Rank Change
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontFamily: 'Avenir', fontSize: extraSmallScreen ? '.7rem' : '1rem' }} gutterBottom>
                  {extraSmallScreen ? "7d" : "7 day"}
                  </Typography>
                </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: extraSmallScreen ? '35px' : '50px'
                  }}>
                    <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: extraSmallScreen ? '1.7rem' : '2rem', fontFamily: 'Avenir' }}>
                      <span style={{ paddingRight: '8px' }}>{displayValue(rankChange7Days.rankChange)}</span> {renderChangeIcon(rankChange7Days.rankChange)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={3}>
                <Box sx={{ bgcolor: colors.secondary100, border: 1, borderColor: colors.secondary500, borderRadius: 3, padding: 2, paddingTop: 1 }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" sx={{ fontFamily: 'Avenir', fontWeight: 'bold', fontSize: extraSmallScreen ? '.7rem' : '1rem' }} gutterBottom>
                      Value Change
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontFamily: 'Avenir', fontSize: extraSmallScreen ? '.7rem' : '1rem' }} gutterBottom>
                      {extraSmallScreen ? "30d" : "30 day"}
                    </Typography>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: extraSmallScreen ? '35px' : '50px'
                  }}>
                  <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: extraSmallScreen ? '1.7rem' : '2rem', fontFamily: 'Avenir' }}>
                    <span style={{ paddingRight: '8px' }}>{displayValue(valueChange30Days.valueChange)}</span> {renderChangeIcon(valueChange30Days.valueChange)}
                  </Typography>
                  </Box>

                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={3}>
                <Box sx={{ bgcolor: colors.secondary100, border: 1, borderColor: colors.secondary500, borderRadius: 3, padding: 2, paddingTop: 1 }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" sx={{ fontFamily: 'Avenir', fontWeight: 'bold', fontSize: extraSmallScreen ? '.7rem' : '1rem' }} gutterBottom>
                      Rank Change
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontFamily: 'Avenir', fontSize: extraSmallScreen ? '.7rem' : '1rem' }} gutterBottom>
                      {extraSmallScreen ? "30d" : "30 day"}
                    </Typography>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: extraSmallScreen ? '35px' : '50px'
                  }}>                  
                    <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: extraSmallScreen ? '1.7rem' : '2rem', fontFamily: 'Avenir' }}>
                      <span style={{ paddingRight: '8px' }}>{displayValue(rankChange30Days.rankChange)}</span> {renderChangeIcon(rankChange30Days.rankChange)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ fontFamily: 'Avenir', fontWeight: 'bold', marginTop: '20px', paddingLeft: '6px' }}
              sx={{ borderBottom: '1px solid #ddd', paddingBottom: '6px', marginBottom: '10px' }}
            >
              Value History
            </Typography>
            {data.length > 0 ? (
              <>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  fontFamily="Avenir"
                  style={{ paddingBottom: '10px', paddingLeft: '6px' }}
                >
                  Value tracking for {name} began on {formattedEarliestDate}
                </Typography>
                <FloatingSelectionFilter
                  options={smallScreen ? ['1W', '1M', '6M', 'All'] : ['1 Week', '1 Month', '6 Months', 'All']}
                  selectedOption={timeScale}
                  handleSelect={handleTimeScaleChange}
                  shrinkGap={smallScreen}
                />
                <ResponsiveContainer width="100%" height={smallScreen ? 200 : 300} style={{ marginLeft: '-24px', paddingTop: '4px' }}>
                  <LineChart data={filteredData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="date"
                      tick={{ fontSize: 12, fill: 'rgba(0, 0, 0, 0.5)' }}
                      tickFormatter={formatXAxis}
                    >
                      {renderYearLabels()}
                    </XAxis>
                    <YAxis
                      domain={[minY, maxY]}
                      tick={{ fontSize: 12, fill: 'rgba(0, 0, 0, 0.5)' }}
                      tickLine={false}
                      axisLine={false}
                      tickMargin={0}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="value"
                      legendType="none"
                      strokeWidth={2}
                      stroke={colors.primary400}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </>
            ) : (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                fontFamily="Avenir"
                fontWeight="bold"
                style={{ paddingBottom: '10px', paddingLeft: '6px' }}
              >
                No value history for player
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </>
  );
};

export default PlayerPage;
