import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, Stack, Grid } from '@mui/material';
import { generateTwoSelection, rankTwoPlayers } from '../../../api/apiService';
import useUserActivity from '../../../hooks/useUserActivity';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85vw', // Adjusted for mobile
  maxWidth: '600px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: {'xs': 2, 'sm': 4}, // Reduced padding for mobile
  overflow: 'auto',
  maxHeight: '90vh',
};

const TwoPlayerModal = () => {
  const [open, setOpen] = useState(false);
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [notSelectedPlayer, setNotSelectedPlayer] = useState(null);
  const isSubmitDisabled = !selectedPlayer;
  const { isActive } = useUserActivity();

  useEffect(() => {
    generateSelection();
  }, []);

  useEffect(() => {
    const randomDelay = Math.random() * (25000 - 15000) + 15000;
    const timer = setTimeout(() => {
      setOpen(true);
    }, randomDelay);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!open && isActive) {
      generateSelection();
      const reopenTimer = setTimeout(() => {
        setOpen(true);
      }, 180000);
      return () => clearTimeout(reopenTimer);
    }
  }, [open, isActive]);

  const handleSubmit = () => {
    const payload = {
      favored: selectedPlayer,
      notFavored: notSelectedPlayer,
    };

    rankTwoPlayers(payload)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error('There was an error sending the player rankings', error);
      });
      setOpen(false);
  };

  const generateSelection = () => {
    generateTwoSelection()
      .then((response) => {
        setPlayers(response.data);
        setSelectedPlayer(null);
        setNotSelectedPlayer(null);
      })
      .catch((error) => {
        console.error('There was an error fetching the player data', error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    setOpen(false);
  }

  const selectPlayer = (selectedPlayer) => {
    setSelectedPlayer(selectedPlayer);
    const notSelected = players.find(player => player.id !== selectedPlayer.id);
    setNotSelectedPlayer(notSelected);
  };

  const renderPlayerButton = (player) => {
    const isSelected = selectedPlayer != null && selectedPlayer.id === player.id;
  
    return (
      <Button
        key={player.id}
        variant={isSelected ? "contained" : "outlined"}
        onClick={() => selectPlayer(player)}
        sx={{
          my: 1,
          width: '100%',
          justifyContent: 'flex-start'
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: 'Avenir' }}>
            {player.name}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ fontFamily: 'Avenir' }}>
            {player.position}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Avenir',
              color: isSelected ? '#fff' : 'text.secondary',
            }}
          >
            {player.age}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Avenir',
              color: isSelected ? '#fff' : 'text.secondary',
            }}
          >
            {player.team}
          </Typography>
        </Stack>
      </Button>
    );
  };
  

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ 
        ...modalStyle, 
        position: 'relative',
        borderRadius: '10px',
        '&:focus-visible': {
          outline: 'none',
        },
      }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ fontFamily: 'Avenir', fontWeight: 'bold', mb: 1 }}
        >
          Player Selection
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: 'Avenir',
            color: 'text.secondary',
            mb: 2,
          }}
        >
          Dynasty, head-to-head, standard points scoring. Your selection helps our rankings!
        </Typography>
        <Grid container spacing={0}>
          {players.map(player => (
            <Grid item xs={12} key={player.id}>
              {renderPlayerButton(player)}
            </Grid>
          ))}
        </Grid>
        <Button 
          variant="contained" 
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          sx={{
            fontFamily: 'Avenir, sans-serif',
            width: '100%',
            mt: 2,
            marginRight: '10px',
          }}
        >
            Submit
        </Button>
        <Button 
          variant="outlined" 
          onClick={handleClose} 
          sx={{
            fontFamily: 'Avenir, sans-serif',
            width: '100%',
            mt: 2,
          }}
        >
          I don't know both players
        </Button>
        {/* <Button variant="outlined" sx={{ fontFamily: 'Avenir', mt: 2, width: '100%' }}>
          
        </Button> */}
      </Box>
    </Modal>
  );
};

export default TwoPlayerModal;
