import React, { useState, useEffect } from 'react';
import { CircularProgress, Alert, FormControl, InputLabel, MenuItem, Select, Button, Box, Typography, Paper, Collapse, TextField, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import colors from '../../config/colors';

const LeagueInput = ({ setLeagueSettingConfig, fetchPlayers, isLoading }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState(14);

  const borderStyle = {
    border: expanded ? `1px solid lightgrey` : 'none',
    borderRadius: '4px',
    padding: '20px'
  };

  const fontSize = { xs: '0.8rem', sm: '0.9rem', md: '1rem' };

  const toggleTextSize = {
    fontSize: {
      xs: '0.6rem',
      sm: '0.75rem',
      md: '0.875rem',
    },
  };

  const initialSettings = {
    "BattingPoints": {
      "Sl_pts": 0,
      "CYC_pts": 0,
      "CS_pts": -1,
      "Double_pts": 3,
      "HBP_pts": 1.5,
      "HR_pts": 6,
      "RBI_pts": 1.5,
      "R_pts": 1.5,
      "SB_pts": 3,
      "SO_pts": 0,
      "BB_pts": 1.5,
      "Triple_pts": 4.5,
      "Single_pts": 1.5,
    },
    "PitchingPoints": {
      "CG_pts": 5,
      "IP_pts": 3,
      "W_pts": 5,
      "L_pts": -3,
      "HLD_pts": 0,
      "SV_pts": 4,
      "BS_pts": -2,
      "ER_pts": -2,
      "H_pts": -1,
      "K_pts": 2,
      "QS_pts": 3,
      "NH_pts": 5,
      "PG_pts": 10,
    },
    "LeagueInfo": {
      "LeagueSize": {
        "SP": 5,
        "RP": 3,
        "C": 1,
        "1B": 1,
        "2B": 1,
        "3B": 1,
        "SS": 1,
        "OF": 3,
        "UT": 1,
      },
      "LeagueFormat": {
        "number_teams": 14,
        "number_weeks": 21
      },
    },
  };

  const [leagueSettings, setLeagueSettings] = useState(initialSettings);
  const [errors, setErrors] = useState({});

  const hittingSettingsMap = {
    Single_pts: "Single",
    Double_pts: "Double",
    Triple_pts: "Triple",
    HR_pts: "Home Run",
    BB_pts: "Walk",
    HBP_pts: "HBP",
    RBI_pts: "RBI",
    R_pts: "Runs",
    SO_pts: "Strikeout",
    SB_pts: "Stolen Base",
    CS_pts: "Caught Stealing",
    Sl_pts: "Grand Slam",
    CYC_pts: "Cycle"
  };

  const pitchingSettingsMap = {
    IP_pts: "Innings Pitched",
    ER_pts: "Earned Runs",
    H_pts: "Hits",
    K_pts: "Strikeout",
    W_pts: "Win",
    L_pts: "Loss",
    HLD_pts: "Hold",
    SV_pts: "Save",
    BS_pts: "Blown Save",
    QS_pts: "Quality Start",
    CG_pts: "Complete Game",
    NH_pts: "No-Hitter",
    PG_pts: "Perfect Game"
  };

  useEffect(() => {
    setLeagueSettingConfig({
      ...leagueSettings,
      year: selectedYear,
      LeagueInfo: {
        ...leagueSettings.LeagueInfo,
        LeagueFormat: {
          ...leagueSettings.LeagueInfo.LeagueFormat,
          number_teams: selectedTeams,
        },
      },
    });
  }, [leagueSettings, setLeagueSettingConfig, selectedYear, selectedTeams]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleTeamsChange = (event) => {
    setSelectedTeams(event.target.value);
  };

  const handleFetchPlayers = () => {
    setHasSubmitted(true);
    if (Object.values(errors).some(error => error)) {
      alert('Please correct the errors before submitting');
      return;
    }
    setExpanded(false); // Collapse the section when submit is pressed
    fetchPlayers();
  };

  const handleRosterSettingChange = (event) => {
    const { name, value } = event.target;
    let normalizedValue = parseInt(value, 10); // Parse as integer
  
    // If the parsed value is not a number, set it to an empty string
    normalizedValue = isNaN(normalizedValue) ? '' : normalizedValue;
  
    setLeagueSettings((prevSettings) => ({
      ...prevSettings,
      LeagueInfo: {
          LeagueSize: {
            ...prevSettings.LeagueInfo.LeagueSize,
            [name]: normalizedValue
          },
          LeagueFormat: {
            ...prevSettings.LeagueInfo.LeagueFormat
          }
      }
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: isNaN(normalizedValue) || normalizedValue < 0 || normalizedValue > 10
    }));
  };
  

  const handleBattingSettingChange = (event) => {
    const { name, value } = event.target;
    let normalizedValue = parseFloat(value);
    normalizedValue = isNaN(normalizedValue) ? '' : Number(normalizedValue.toFixed(2)); // Round to two decimal places

    // Remove leading zeros
    if (typeof normalizedValue === 'number' && value.length > 1) {
        normalizedValue = normalizedValue.toString().replace(/(?<=0)0+/g, '');
    }

    setLeagueSettings((prevSettings) => ({
      ...prevSettings,
      BattingPoints: {
        ...prevSettings.BattingPoints,
        [name]: normalizedValue
      }
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: isNaN(normalizedValue) || normalizedValue < -20 || normalizedValue > 20
    }));
  };

  const handlePitchingSettingChange = (event) => {
    const { name, value } = event.target;
    let normalizedValue = parseFloat(value);
    normalizedValue = isNaN(normalizedValue) ? '' : Number(normalizedValue.toFixed(2)); // Round to two decimal places

    // Remove leading zeros
    if (typeof normalizedValue === 'number' && value.length > 1) {
        normalizedValue = normalizedValue.toString().replace(/(?<=0)0+/g, '');
    }

    setLeagueSettings((prevSettings) => ({
      ...prevSettings,
      PitchingPoints: {
        ...prevSettings.PitchingPoints,
        [name]: normalizedValue
      }
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: isNaN(normalizedValue) || normalizedValue < -20 || normalizedValue > 20
    }));
  };

  const handleRosterBlur = (event) => {
    const { name, value } = event.target;
    if (value === '') {
      setLeagueSettings((prevSettings) => ({
        ...prevSettings,
        LeagueInfo: {
            LeagueSize: {
              ...prevSettings.LeagueInfo.LeagueSize,
              [name]: 1
            },
            LeagueFormat: {
              ...prevSettings.LeagueInfo.LeagueFormat
            }
        }
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value === '') {
      const isBattingPoint = name in hittingSettingsMap;
      setLeagueSettings((prevSettings) => ({
        ...prevSettings,
        [isBattingPoint ? 'BattingPoints' : 'PitchingPoints']: {
          ...prevSettings[isBattingPoint ? 'BattingPoints' : 'PitchingPoints'],
          [name]: 0
        }
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false
      }));
    }
  };

  const resetLeagueSettings = (event) => {
    event.stopPropagation();
    setLeagueSettings(initialSettings);
  };

  const displayLeagueDetails = () => {
    return (
      <Box>
        <Typography variant="h6" sx={{ p: 1, fontSize: '1rem', color: colors.primary400, fontFamily: 'Avenir' }}>Lineup</Typography>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {Object.keys(leagueSettings.LeagueInfo.LeagueSize).map((key) => (
            <Grid item xs={4} sm={3} md={2} key={key}>
              <TextField
                label={key}
                name={key}
                type="number"
                value={leagueSettings.LeagueInfo.LeagueSize[key]}
                onChange={handleRosterSettingChange}
                onBlur={handleRosterBlur}
                margin="normal"
                variant="outlined"
                fullWidth
                size="small"
                inputProps={{
                  style: { fontSize: '.8rem' },
                  min: 0,
                  max: 10,
                  step: 1,
                }}
                error={!!errors[key]}
                helperText={errors[key] ? 'Expecting value between 0 and 10' : ''}
              />
            </Grid>
          ))}
        </Grid>
        <Typography variant="h6" sx={{ p: 1, fontSize: '1rem', color: colors.primary400, fontFamily: 'Avenir' }}>Batting Points</Typography>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {Object.keys(hittingSettingsMap).map((key) => (
            <Grid item xs={4} sm={3} md={2} key={key}>
              <TextField
                label={hittingSettingsMap[key]}
                name={key}
                type="number"
                value={leagueSettings.BattingPoints[key]}
                onChange={handleBattingSettingChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                fullWidth
                size="small"
                inputProps={{
                  style: { fontSize: '.8rem' },
                  min: -20,
                  max: 20,
                  step: 0.5,
                }}
                error={!!errors[key]}
                helperText={errors[key] ? 'Expecting value between -20 and 20' : ''}
              />
            </Grid>
          ))}
        </Grid>
        <Typography variant="h6" sx={{ p: 1, fontSize: '1rem', color: colors.primary400, marginTop: '10px', fontFamily: 'Avenir' }}>Pitching Points</Typography>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {Object.keys(pitchingSettingsMap).map((key) => (
            <Grid item xs={4} sm={3} md={2} key={key}>
              <TextField
                label={pitchingSettingsMap[key]}
                name={key}
                type="number"
                value={leagueSettings.PitchingPoints[key]}
                onChange={handlePitchingSettingChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                fullWidth
                size="small"
                inputProps={{
                  style: { fontSize: '.8rem' },
                  min: -20,
                  max: 20,
                  step: 0.5
                }}
                error={!!errors[key]}
                helperText={errors[key] ? 'Expecting value between -20 and 20' : ''}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const hasErrors = Object.values(errors).some(error => error);

  const isSettingsEdited = () => {
    const compareObjects = (obj1, obj2) => {
      return Object.keys(obj1).every((key) => {
        if (typeof obj1[key] === 'object' && obj1[key] !== null) {
          return compareObjects(obj1[key], obj2[key]);
        }
        return obj1[key] === obj2[key];
      });
    };
    
    return !compareObjects(initialSettings, leagueSettings);
  };

  return (
    <>
      {!hasSubmitted && (
        <Alert severity="info" sx={{ marginBottom: 2, fontFamily: 'Avenir' }}>Select year and league settings to calculate jWAR</Alert>
      )}
      <Paper key="leagueInput" elevation={0} sx={{ my: 2, p: 2, outline: '1px solid', outlineColor: 'lightgrey' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', width: '100%' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: '10px'
          }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                gap: '10px'
            }}>
                <FormControl variant="outlined" sx={{ minWidth: 120, margin: '10px 0 10px 0', width: { xs: '100%' } }}>
                <InputLabel sx={{ fontSize: '1rem' }}>Year</InputLabel>
                <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    label="Year"
                    sx={{ fontSize }}
                >
                    {[...Array(2025 - 2011).keys()].map(offset => {
                        const year = 2011 + offset;
                        return <MenuItem key={year} value={year}>{year}</MenuItem>;
                    })}
                </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ minWidth: 120, margin: '10px 0 10px 0', width: { xs: '100%' } }}>
                <InputLabel sx={{ fontSize: '1rem' }}>League Size</InputLabel>
                <Select
                    value={selectedTeams}
                    onChange={handleTeamsChange}
                    label="League Size"
                    sx={{ fontSize }}
                >
                    {[...Array(30 - 8 + 1).keys()]
                    .map(offset => 8 + offset)
                    .filter(num => num % 2 === 0)
                    .map(teams => (
                        <MenuItem key={teams} value={teams}>{teams}</MenuItem>
                    ))
                    }
                </Select>
                </FormControl>
            </Box>
            <Button
              variant="contained"
              onClick={handleFetchPlayers}
              sx={{
                fontSize: '0.875rem',
                fontFamily: 'Avenir, sans-serif',
                width: { xs: '100%', sm: 'auto' },
                height: { xs: '40px', sm: '56px' },
              }}
              disabled={isLoading || hasErrors}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Typography sx={{ padding: '0 30px', fontSize: 'inherit' }}>Submit</Typography>
              )}
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', cursor: 'pointer' }} onClick={handleExpandClick}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '2px' }}>
                <Button 
                    onClick={() => setExpanded(!expanded)} 
                    startIcon={<ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />} 
                    sx={{ ...toggleTextSize }}
                >
                    {expanded ? 'Hide league settings' : 'Edit league settings'}
                    {isSettingsEdited() && <EditIcon sx={{ marginLeft: '4px', fontSize: {xs: '.8rem', sm: '.9rem', md: '1rem'}}} />}
                </Button>
            </Box>
            {isSettingsEdited() && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '2px' }}>
                    <Button 
                        onClick={resetLeagueSettings} 
                        sx={{ ...toggleTextSize }}
                    >
                        Reset league settings
                    </Button>
                </Box>
            )}
            </Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{ marginBottom: '10px' }}>
              <Box sx={borderStyle}>
                {displayLeagueDetails()}
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Paper>
    </>
  );
};

export default LeagueInput;
