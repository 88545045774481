import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LandingPage from './containers/LandingPage/LandingPage';
import Rankings from './components/Rankings/Rankings';
import usePlayerRankings from './hooks/usePlayerRankings';
import useSelectionsCount from './hooks/useSelectionsCount';
import TwoPlayerModal from './components/Picker/PickerModal/TwoPlayerModal';
import Picker from './components/Picker/Picker';
import TradeCalculator from './components/TradeCalculator/TradeCalculator';
import JWar from './components/JWar/JWar';
import Layout from './containers/Layout/Layout';
import FAQ from './components/FAQ/FAQ';
import PlayerPage from './components/PlayerPage/PlayerPage';
import PageNotFound from './containers/PageNotFound';
import CookiesPopup from './components/CookiesPopup/CookiesPopup';
import PrivacyPolicyPage from './containers/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsOfUsePage from './containers/TermsOfUsePage/TermsOfUsePage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import theme from './config/theme';

function App() {
  const { players, playersError, lastUpdated } = usePlayerRankings();
  const { selectionsCount, selectionsCountError } = useSelectionsCount();

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <CookiesPopup />
        <BrowserRouter>
        <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="picker" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError} >
                <Picker />
              </Layout>
            } />
            <Route path="rankings" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <Rankings players={players} error={playersError} lastUpdated={lastUpdated} />
              </Layout>
            } />
            <Route path="calculator" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <TradeCalculator />
              </Layout>
            } />
            <Route path="jwar" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <JWar />
              </Layout>
            } />
            <Route path="faq" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <FAQ />
              </Layout>
            } />
            <Route path="player/:id" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <PlayerPage />
              </Layout>
            } />
            <Route path="privacy-policy" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <PrivacyPolicyPage />
              </Layout>
            } />
            <Route path="terms-of-service" element={
              <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                <TermsOfUsePage />
              </Layout>
            } />
            <Route path="*" element={<PageNotFound />} /> {/* Catch-all route for 404 */}
          </Routes>
          <TwoPlayerModal />
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
